<style lang="scss">
#debug {
  display: none;
}

#frontend {
  .impressum-all {
    letter-spacing: 1px;

    .sub-headline {
      font-size: 140%;
      font-family: 'Helvetica Neue LT W05_75 Bold';
      letter-spacing: 1px;
      margin-top: 10px;
    }

    h2 {
      text-transform: none;
      letter-spacing: 1px;
      font-size: 180%;
    }

    a {
      display: inline-block;
      //border:1px solid red;
      font-family: 'Helvetica Neue LT W05_75 Bold';

      &:hover {
        color: #000;
      }
    }

    .smd-link {
      font-size: 140%;
      margin: 10px 0;
    }

    .line {
      width: 100%;
      border-top: 1px solid #000;
      margin-bottom: 80px;
      margin-top: 30px;
    }

    h4 {
      font-family: 'Helvetica Neue LT W05_75 Bold';
      margin-top: 40px;
    }

    .firmen-liste {
      h4 {
        margin-bottom: 40px;
      }

      h5 {
        margin-bottom: 30px;
      }

      span {
        display: block;
        margin-top: 20px;
        font-family: 'Helvetica Neue LT W05_75 Bold';
        font-size: 140%;
        margin-bottom: 10px;
      }

      h6 {
        margin-top: 20px;
        font-size: 140%;
        font-family: 'Helvetica Neue LT W05 56 Italic';
      }

      p {
        margin-bottom: 30px;
      }
    }

    $padding: 30px;

    .indent-1 {
      padding-left: 1 * $padding;
    }

    .indent-2 {
      padding-left: 2 * $padding;
    }

    .indent-3 {
      padding-left: 3 * $padding;
    }
  }
}
</style>

<template>
  <div class="impressum-all">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="headline-box-std">
            <div class="headline-inner">
              <h1>{{ $t('General.impressum') }}</h1>
            </div>
          </div>
        </div>
      </div>
      <div v-if="$i18n.locale == 'de'">
        <div class="row padding-tb-s justify-content-center">
          <div class="col-10">
            <h2 class=" text-lg-center">Impressum / Informationen gem. §§ 24 MedienG, 14 UGB, 5 ECG</h2>
          </div>
        </div>
        <div class="row justify-content-center text-lg-center padding-b-s">
          <div class="col-lg-5 col-10 align-self-center">
            <p>
              Medieninhaber / Herausgeber / Redaktion:<br />
              Styria Media Group AG<br />
              Corporate Communications<br />
              Gadollaplatz 1<br />
              8010 Graz
            </p>

            <p>
              Firmenbuchnummer: FN 142663z<br />
              Firmenbuchgericht: Landesgericht f. ZRS Graz<br />
              UID-Nr: ATU 43133701
            </p>
          </div>
          <div class="col-lg-5 col-10 align-self-center">
            <p>
              Telefon: <a href="tel:+4331680630">+43 (0)316 8063-0</a><br />
              Fax : <a href="tel:+4331680631004">+43 (0)316 8063-1004</a><br />
              Email: <a href="mailto:communication@styria.com">communication@styria.com</a>
            </p>
            <p>
              Redaktionelle Verantwortung<br />
              Corporate Communications<br />
              Telefon: <a href="tel:+4331680631078">+43 (0)316 8063-1078</a><br />
              E-Mail: <a href="mailto:communication@styria.com">communication@styria.com</a><br />
            </p>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-12 text-lg-center padding-tb-s">
            <h3>Webdesign</h3>
            <a href="https://www.styriamediadesign.at" target="_blank" class="smd-link">Styria Media Design</a><br />
            <p>Alexander Marko und Christian Wilms, Geschäftsleitung</p>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-7 col-10 text-lg-center padding-tb-s">
            <small>Alle Rechte vorbehalten. Eine Vervielfältigung zum eigenen und privaten Gebrauch gem. § 42 UrhG ist
              gestattet. Jegliche darüber hinausgehende Verwertung ist verboten. </small>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-lg-8 col-10">
            <div class="line"></div>
            <h4>Offenlegung gemäß § 25 MedienG der Styria Media Group AG und</h4>
            <h4>Ergänzung zu den Offenlegungen gemäß § 25 MedienG der Tochtergesellschaften</h4>
            <p>Styria Media Group AG, 8010 Graz, Gadollaplatz 1.</p>
            <p>Betriebsgegenstand: Medienwesen.</p>
            <p>Vorstand: Mag. Markus Mair (Vorsitzender), DI (FH) Bernhard Kiener, Mag. Herwig Langanger</p>
            <p>Aufsichtsrat: Dr. Friedrich Santner (Vorsitzender), Generaldirektor Dr. Othmar Ederer (Stellvertreter des
              Vorsitzenden), Mag.a Eva Heigl, Mag. a Petra Schachner-Kröll, Dr. Karl Schleinzer (&#10013;), Ute Groß,
              Ing. Josef
              Klapsch, Michael Lohmeyer.</p>
            <p>Die Styria Media Group AG steht zu 98,33% im Eigentum der Katholischer Medien Verein Privatstiftung, 8010
              Graz, Gadollaplatz 1;</p>

            <p>Stifter: Der Katholischer Medien Verein und die Herren Dr. Josef Heuberger, Dr. h. c. Franz Küberl und
              Regens Mag. Franz Josef Rauch.</p>
            <p>Begünstigte: Der oder die Begünstigten sind vom Stiftungsvorstand zu bestimmen. Die Bestimmung hat unter
              Berücksichtigung des Zweckes der Stiftung ausschließlich für gemeinnützige, mildtätige oder kirchliche
              Zwecke zu erfolgen.</p>

            <h4>Zweck der Stiftung</h4>
            <p>Erstens: Die Stiftung verfolgt ausschließlich gemeinnützige, mildtätige und kirchliche Zwecke im Sinne
              des §§ 34 ff Bundesabgabenordnung in der jeweils gültigen Fassung und ist nicht auf Gewinnerzielung
              ausgerichtet.</p>
            <p>
              Zweitens: Die Stiftung fördert aus ihren Erträgen christliche Medienarbeit, insbesondere die Bildung von
              Medienfachleuten, die Herstellung und Verbreitung von Medienwerken aller Art, die dem Geist der
              katholischen Kirche und ihrer Lehre, dem Dialog zwischen Glaube und Wissenschaft, der ökumenischen und
              interreligiösen Begegnung, der katholischen Soziallehre, dem Schutz der
              Menschenwürde und den Grundsätzen von Demokratie und Völkerverständigung dienen.
            </p>
            <p>Drittens: Die Stiftung kann hilfsbedürftige Personen unterstützen.</p>
            <p>Viertens: Die Stiftung kann zu Verwirklichung zumindest eines der von ihr verfolgten begünstigten Zwecke
              Mittel teilweise oder ausschließlich für die Vergabe von Stipendien oder Preisen zur Förderung der in § 40
              b (1) BAO angeführten Zwecke und unter Berücksichtigung der übrigen Voraussetzungen des § 40 b BAO
              gewähren.</p>

            <p>Dem Stiftungsvorstand gehören an: Vorsitzender Generaldirektor Dr. Othmar Ederer, Stellvertreter des
              Vorsitzenden Dr. Friedrich Santner, Dr. Karl Schleinzer (&#10013;), Mag. Sieglinde Pailer.</p>
            <p>Die Styria Media Group AG steht weiters zu 1,67% im Eigentum des Katholischer Medien Verein, 8010 Graz,
              Gadollaplatz 1. Dessen Vorstand setzt sich zusammen aus Obmann, Obmann Stellvertreter, Kassier und
              Schriftführer.</p>
            <p>Obmann: Generaldirektor Dr. Othmar Ederer, Obmann Stellvertreter: Dr. Friedrich Santner, Kassier: Mag.
              Sieglinde Pailer, Schriftführer: Mag. Herbert Peklar MSc.</p>
            <h4>Vereinszweck</h4>
            <p>
              1. Der Verein fördert christliche Medienarbeit, insbesondere die Bildung von Medienfachleuten, die
              Herstellung und Verbreitung von Medienwerken aller Art, die dem Geist der katholischen Kirche und ihrer
              Lehre, den Dialog zwischen Glauben und Wissenschaft, der ökumenischen und interreligiösen Begegnung, der
              katholischen Soziallehre, dem Schutz der Menschenwürde und den Grundsätzen der
              Demokratie und Völkerverständigung dienen.
            </p>
            <p>2. Der Verein verfolgt ausschließlich gemeinnützige, caritative und/oder kirchliche Zwecke im Sinne der
              §§ 34 ff Bundesabgabenordnung in der jeweils gültigen Fassung.</p>

            <p>
              Die <u>Styria Media Group AG</u> wurde schon im Jahr 1869 von einem <u>Verein</u> von Katholiken
              grundgelegt. Seither bemühen sich Mitarbeiterinnen und Mitarbeiter aus vielen Ländern, Kulturen und
              Religionen diesen weltanschaulichen Wurzeln durch einen besonders sorgfältigen Umgang mit der Würde des
              Menschen - im umfassenden Sinn - gerecht zu werden. Heute gehört die Styria Media
              Group AG weit überwiegend (98,33%) der oben erwähnten <u>gemeinnützigen</u> Privatstiftung, die selbst von
              Gesetz wegen eigentümerloses Vermögen ist. Die restlichen 1,67% sind beim schon erwähnten Verein
              geblieben. Dieser Hintergrund macht die Styria Media Group AG zu einem Unternehmen, in dem Werte zählen.
              Er sorgt aber gleichzeitig auch dafür, dass die Styria Media Group AG selbst
              und ihre Tochterunternehmungen <u>unabhängig</u> sind, insbesondere auch von politischen Parteien,
              Interessensvertretungen und Kirchen.
            </p>
          </div>
        </div>
        <div class="row justify-content-center firmen-liste">
          <div class="col-lg-8 col-10">
            <p><u>Die Styria Media Group AG ist weiters Inhaberin von folgenden Medienunternehmen:</u></p>
            <p>
              Styria Medienhaus Wien GmbH, Wien,<br />
              Betriebsgegenstand: Holdinggesellschaft.
            </p>

            <p class="indent-1"><u>Styria Medienhaus Wien GmbH ist weiters Inhaberin von folgenden
                Medienunternehmen:</u></p>
            <p class="indent-1">
              „Die Presse“ Verlags-Gesellschaft m.b.H. &amp; Co KG, Wien,<br />
              Betriebsgegenstand: Herausgabe der Tageszeitung „Die Presse“, der Wochenzeitung „Die Presse“ am Sonntag
              und der Betrieb eines Onlinedienstes.
            </p>
            <p class="indent-1">
              Content Performance Group GmbH, Wien,<br />
              Betriebsgegenstand: Herausgabe und Vertrieb von Content Marketing Produkten und der Betrieb eines
              Onlinedienstes.
            </p>

            <p class="">
              Styria Medienhaus Lifestyle GmbH &amp; Co KG, Wien,
              <br />Betriebsgegenstand: Herausgabe der Zeitschriften „DIVA“, „WIENERIN“ und anderen Magazinen.
            </p>
            <p class="indent-1"><u>Styria Medienhaus Lifestyle GmbH &amp; Co KG ist weiters Inhaberin von folgenden
                Medienunternehmen:</u></p>
            <p class="indent-1">missMEDIA GmbH, Wien,<br />Betriebsgegenstand: Herausgabe der Zeitschrift „miss“ und der
              Betrieb eines Onlinedienstes.</p>

            <p class="">
              Styria Medienhaus Sport GmbH &amp; Co KG, Wien,<br />
              Betriebsgegenstand: Herausgabe der Zeitschrift „Sportmagazin“ und anderen Magazinen.
            </p>

            <p class="indent-2"><u>Styria Medienhaus Sport GmbH &amp; Co KG ist weiters Inhaberin von folgenden
                Medienunternehmen:</u></p>
            <p class="indent-2">Ligaportal GmbH, Sattledt,<br />Betriebsgegenstand: Betrieb eines Onlinedienstes</p>

            <p class="">Top Times Medien GmbH, Graz,<br />Betriebsgegenstand: Herausgabe von Zeitschriften und
              Magazinen, insbesondere „SPORTaktiv“ und der Betrieb eines Onlinedienstes.</p>
            <p class="">Die Furche – Zeitschriften-Betriebs-Gesellschaft m.b.H. &amp; Co KG,
              Wien,<br />Betriebsgegenstand: Herausgabe der Wochenzeitung „DIE FURCHE“ und der Betrieb eines
              Onlinedienstes.</p>
            <p class="">Styria Media Regional GmbH, Graz,<br />Betriebsgegenstand: Vermögensverwaltung.</p>

            <p class="indent-1"><u>Die Styria Media Regional GmbH ist weiters Inhaberin von folgenden
                Medienunternehmen:</u></p>
            <p class="indent-1">
              Kleine Zeitung GmbH &amp; Co KG, Graz,<br />
              Betriebsgegenstand: Verlagswesen, insbesondere die Herausgabe der Tageszeitung „KLEINE ZEITUNG“, und der
              Betrieb eines Onlinedienstes sowie einer Werbeagentur.
            </p>
            <p class="indent-2"><u>Die Kleine Zeitung GmbH &amp; Co KG ist weiters Inhaberin von folgenden
                Medienunternehmen:</u></p>
            <p class="indent-2">
              Content Performance Group GmbH, Wien,<br />
              Betriebsgegenstand: Herausgabe und Vertrieb von Content Marketing Produkten und der Betrieb eines
              Onlinedienstes.
            </p>
            <p class="indent-2">
              MM Produktion und Services GmbH, Graz,<br />
              Betriebsgegenstand: Betrieb eines Verlages in Form eines Eigenverlages insbesondere zur Herausgabe von
              Verzeichnissen über Ballveranstaltungen und der Betrieb eines Onlinedienstes.
            </p>

            <p class="indent-1">
              Antenne Steiermark Regionalradio GmbH &amp; Co KG, Graz,<br />
              Betriebsgegenstand: Hörfunk.
            </p>
            <p class="indent-1">
              Antenne Kärnten Regionalradio GmbH &amp; Co KG, Klagenfurt,<br />
              Betriebsgegenstand: Hörfunk.
            </p>
            <p class="indent-1">
              Schlagerradio Flamingo GmbH, Graz,<br />
              Betriebsgegenstand: Hörfunk.
            </p>

            <p class="indent-1">
              RMA Beteiligungsverwaltungs GmbH, Wien<br />
              Betriebsgegenstand: Holdinggesellschaft
            </p>
            <p class="indent-2"><u>Die RMA Beteiligungsverwaltungs GmbH ist weiters Inhaberin von folgenden
                Medienunternehmen:</u></p>
            <p class="indent-2">
              RegionalmedienAustria AG, Wien,<br />
              Betriebsgegenstand: Medienunternehmen.
            </p>

            <p>
              Styria Digital Marketplaces GmbH, Graz<br />
              Betriebsgegenstand: Holdinggesellschaft.
            </p>

            <p>
              Styria Services Holding GmbH,<br />
              Betriebsgegenstand: Holdinggesellschaft.
            </p>
            <p class="indent-1"><u>Die Styria Services Holding GmbH ist weiters Inhaberin von folgenden
                Medienunternehmen:</u></p>

            <p class="indent-1">
              rca radio content austria GmbH, Graz<br />
              Betriebsgegenstand: Erstellung, Vertrieb und Erwerb von audiovisuellen Inhalten
            </p>
          </div>
        </div>
      </div>
      <div v-if="$i18n.locale == 'en'">
        <div class="row justify-content-center text-lg-center padding-b-s">
          <div class="col-lg-8 col-10 align-self-center">
            <p>
              Editorial Responsibility<br />
              Corporate Communication <br />
              Gadollaplatz 1<br />
              8010 Graz
            </p>

            <p>
              Company register number: FN 142663z<br />
              Commercial court: Landesgericht f. ZRS Graz<br />
              UID No: ATU 43133701
            </p>
          </div>
          <div class="col-lg-8 col-10 align-self-center">
            <p>
              Editorial responsibility<br />
              Corporate Communication<br />
              Telephone: <a href="tel:+4331680631078">+43/316/8063-1078</a><br />
              Email: <a href="mailto:communication@styria.com">communication@styria.com</a><br />
            </p>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-12 text-lg-center padding-tb-s">
            <h3>Web design</h3>
            <a href="https://www.styriamediadesign.at" target="_blank" class="smd-link">Styria Media Design</a><br />
            <p>Alexander Marko and Christian Wilms, Management</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Footer from '../../../components/Frontend/Footer.vue';

import { mdiChevronUp } from '@mdi/js';

export default defineComponent({
  meta: {
    de: {
      title: 'Impressum | Styria Media Group',
      keywords: ['imprint', 'impressum', 'styria media group', 'styria'],
      description: 'Impressum. Styria Media Group AG, Gadollaplatz 1 8010 Graz',
      image: require('@/assets/img/og/startseite.png'),
    },
    en: {
      title: 'Imprint | Styria Media Group',
      keywords: ['imprint', 'styria media group', 'styria'],
      description: 'Imprint. Styria Media Group AG, Gadollaplatz 1 8010 Graz',
      image: require('@/assets/img/og/startseite.png'),
    },
  },
  components: {
    Footer,
  },
  setup() {
    return {
      mdiChevronUp,
    };
  },
});
</script>
